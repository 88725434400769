import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { ResponsiveContainer, LineChart, BarChart, Line, Bar, YAxis, XAxis, Tooltip, Legend, CartesianGrid, ComposedChart } from 'recharts';

const styles = theme => ({
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
    table: {
        // temporary right-to-left patch, waiting for
        // https://github.com/bvaughn/react-virtualized/issues/454
        '& .ReactVirtualized__Table__headerRow': {
            flip: false,
            paddingRight: theme.direction === 'rtl' ? '0px !important' : undefined,
        },
        // overflowX: 'auto'
    },
    tableRow: {
        cursor: 'pointer',
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
    tableRowHover: {
        '&:hover': {
            backgroundColor: theme.palette.grey[200],
        },
    },
    tableCell: {
        flex: 1,
        whiteSpace: "nowrap",
    },
    noClick: {
        cursor: 'initial',
    },
});

class LineBarChart extends React.PureComponent {
    formatter(value, name, props) {
        const { payload } = props
        return [`${value}`, `${payload.confirmation_at}の${name}`]
    }
    render() {
        const { width, height, data, key_x, y } = this.props;
        return (
            <>
                <div style={{ width: '100%', height: 300 }}>
                    <ResponsiveContainer>
                        <ComposedChart
                            width={width}
                            height={height}
                            data={data}
                            syncId="line_bar_id"
                            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey={key_x} width={10} />
                            <YAxis />
                            <Tooltip formatter={this.formatter}/>
                            <Legend />
                            <Line type="monotone" dataKey={y.line.key} stroke="#8884d8" name={y.line.name} />
                            <Bar dataKey={y.bar.key} barSize={20} fill="#413ea0" name={y.bar.name} />
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
            </>
        );
    }
}

LineBarChart.propTypes = {
    classes: PropTypes.object.isRequired,
    headerHeight: PropTypes.number,
    onRowClick: PropTypes.func,
    rowHeight: PropTypes.number,
};

export default withStyles(styles)(LineBarChart);
