import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { AutoSizer, List, WindowScroller } from 'react-virtualized';

const styles = theme => ({
    flexContainer: {
        display: 'flex',
        alignItems: 'center',
        boxSizing: 'border-box',
    },
    noClick: {
        cursor: 'initial',
    },
});

class ReactVirtualizedList extends React.PureComponent {
    static defaultProps = {
        headerHeight: 48,
        rowHeight: 48,
    };
    constructor(props, context) {
        super(props, context);
        this.noRowsRenderer = this.noRowsRenderer.bind(this);
        this.renderRow = this.renderRow.bind(this);
    }

    noRowsRenderer() {
        return <div>noRow</div>
    }

    renderRow({
        index,
        isScrolling,
        isVisible,
        key,
        parent,
        style,
    }) {
        const { data, renderRow } = this.props
        return renderRow(key, style, data[index]);
    }

    render() {
        const { data } = this.props
        return (
            <WindowScroller
                scrollElement={null}
            >
                {({ height, isScrolling, onChildScroll, scrollTop }) => {
                    return (
                        <AutoSizer>
                            {({ height, width }) => (
                                <List
                                    width={width}
                                    height={height}
                                    rowCount={data.length}
                                    isScrolling={isScrolling}
                                    rowHeight={300}
                                    overscanRowCount={2}
                                    noRowsRenderer={this.noRowsRenderer}
                                    rowRenderer={this.renderRow}
                                />
                            )}
                        </AutoSizer>
                    );
                }}
            </WindowScroller>
        );
    }
}

ReactVirtualizedList.propTypes = {
    classes: PropTypes.object.isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            dataKey: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            numeric: PropTypes.bool,
            width: PropTypes.number.isRequired,
        }),
    ).isRequired,
    headerHeight: PropTypes.number,
    onRowClick: PropTypes.func,
    rowHeight: PropTypes.number,
};

export default withStyles(styles)(ReactVirtualizedList);
