import React, { useState } from 'react'
import { graphql, navigate } from 'gatsby'

import _ from 'lodash'
import dateformat from 'dateformat'
import styled from '@emotion/styled'
import Paper from '@material-ui/core/Paper';
import GridBase from "@material-ui/core/Grid";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

import PropTypes from 'prop-types'
import Layout from '../../../Templates/Layout'
import Container from '../../../Templates/Container'
import Header from '../../../Templates/Header'
import ReactVirtualizedTable from '../../../Organisms/ReactVirtualizedTable'
import ReactVirtualizedList from '../../../Organisms/ReactVirtualizedList'
import LineBarChart from '../../../Organisms/LineBarChart'

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const Grid = styled(GridBase)`
  .MuiGrid-root {
    flex-grow: 1;
  }
`

const dataTransform = (org) => {
    const rows = _.map(org, (data) => {
        return {
            ...data.node,
            age: data.node.age.name,
            age_no: data.node.age.no,
            gender_id: data.node.gender.id,
            gender_name: data.node.gender.name,
            confirmation_at: data.node.confirmation_at && dateformat(new Date(data.node.confirmation_at.replace(/-/g, "/")), 'yyyy/mm/dd'),
            prefecture_name: data.node.prefecture.name,
            status_name: data.node.status.name,
            residence: data.node.residence,
            created_at: data.node.created_at && dateformat(new Date(data.node.created_at.replace(/-/g, "/")), 'yyyy/mm/dd'),
            updated_at: data.node.updated_at && dateformat(new Date(data.node.updated_at.replace(/-/g, "/")), 'yyyy/mm/dd'),
        }
    })

    const confirmationAtGrouped = _(rows).uniqBy('confirmation_at').map((row) => {
        return { "confirmation_at": row.confirmation_at }
    }).sortBy('confirmation_at').map((row) => {
        return {
            ...row,
            count: _.filter(rows, ['confirmation_at', row.confirmation_at]).length,
        }
    }).value()

    const confirmationAtLineBar = _(confirmationAtGrouped).map((row) => {
        const confirmation_at = row.confirmation_at
        return {
            ...row,
            sub_total: _.sumBy(confirmationAtGrouped, (o) => {
                return o.confirmation_at <= confirmation_at ? o.count : 0
            }),
        }
    }).value()

    const prefectureGrouped = _(rows).uniqBy('pref_code').map((row) => {
        return { "pref_code": row.pref_code, "prefecture_name": row.prefecture_name }
    }).sortBy('pref_code').map((row) => {
        return {
            ...row,
            count: _.filter(rows, ['pref_code', row.pref_code]).length
        }
    }).value()

    const genderGrouped = _(rows).uniqBy('gender_id').map((row) => {
        return { "gender_id": row.gender_id, "gender_name": row.gender_name }
    }).map((row) => {
        return {
            ...row,
            count: _.filter(rows, ['gender_id', row.gender_id]).length
        }
    }).value()
    const ageGrouped = _(rows).uniqBy('age').map((row) => {
        return { "age": row.age, 'age_no': row.age_no }
    }).sortBy('age_no').map((row) => {
        return {
            ...row,
            count: _.filter(rows, ['age', row.age]).length
        }
    }).value()

    const updateAt = _(rows).map((row) => {
        return { "update_at": row.update_at > row.created_at ? row.updateAt : row.created_at }
    }).maxBy('update_at').update_at//, _(rows).maxBy('updated_at').updated_at
    return {
        rows: rows,
        confirmationAtGrouped: confirmationAtGrouped,
        prefectureGrouped: prefectureGrouped,
        genderGrouped: genderGrouped,
        ageGrouped: ageGrouped,
        updateAt: updateAt,
        confirmationAtLineBar: confirmationAtLineBar
    }
}

const InfectedPersonCard = (props) => {
    const { prefecture_name, no } = props.data
    const { gender_name, age, nationality, municipality, profession, status_name } = props.data
    const { start_at, confirmation_at, dead_at, end_at } = props.data
    const { residence } = props.data
    return (
        <Card style={{ margin: "5px", maxHeight: "350px" }}>
            <CardContent>
                <Typography variant="h5" component="h2">
                    {prefecture_name}:{no}例目の感染者
                </Typography>
                <Typography variant="body2" component="p">
                    性別: {gender_name}
                </Typography>
                <Typography variant="body2" component="p">
                    年齢: {age}
                </Typography>
                <Typography variant="body2" component="p">
                    国籍: {nationality}
                </Typography>
                <Typography variant="body2" component="p">
                    都市: {municipality}
                </Typography>
                <Typography variant="body2" component="p">
                    職業: {profession}
                </Typography>
                <Typography variant="body2" component="p">
                    状態: {status_name}
                </Typography>
                <Typography variant="body2" component="p">
                    症状自覚日: {start_at}
                </Typography>
                <Typography variant="body2" component="p">
                    感染確認日: {confirmation_at}
                </Typography>
                <Typography variant="body2" component="p">
                    陰性確認日: {end_at}
                </Typography>
                {
                    dead_at &&
                    <Typography variant="body2" component="p">
                        死亡日: {end_at}
                    </Typography>
                }
                <Typography variant="body2" component="p">
                    メモ: {residence}
                </Typography>
            </CardContent>
        </Card>
    );
}

const InfectedPersons = ({ data, pageContext }) => {
    const [keyword, setKeyword] = useState("");
    const [tabIndex, setTabIndex] = useState(1);
    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
    };
    const title = "COVID-19(新型コロナウイルス)の国内感染状況 | Not Be Forgotten"
    const description = "COVID-19(新型コロナウイルス)の国内感染状況を随時更新していきます"
    const seoProps = {
        title: title,
        desc: description,
        image: "./covid-19.png",
        pathname: "",
        article: false
    }
    const headerProps = {
        title: title,
        data: {},
        cover: "",
    }
    const {
        rows,
        confirmationAtGrouped,
        prefectureGrouped,
        genderGrouped,
        ageGrouped,
        updateAt,
        confirmationAtLineBar
    } = dataTransform(data.allCovid19.edges)

    return (
        <Layout seo={seoProps}>
            <Header {...headerProps} />
            <Container>
                <Grid container spacing={1} p={1} m={5}>
                    <Grid item xs={12} md={12} >
                        COVID-19の国内感染者に関する情報を日々更新しています。<br />
                        現在は、{rows.length}人の感染者が確認されています。<br />
                        更新日: {updateAt}
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Tabs value={tabIndex} onChange={handleChange} aria-label="simple tabs example">
                            <Tab value={1} label="グラフ" id={`simple-tab-1`} aria-controls={`simple-tabpanel-1`} />
                            <Tab value={2} label="データサマリ" id={`simple-tab-2`} aria-controls={`simple-tabpanel-2`} />
                            <Tab value={3} label="データ" id={`simple-tab-3`} aria-controls={`simple-tabpanel-3`} />
                        </Tabs>
                    </Grid>
                </Grid>
                <div hidden={tabIndex !== 1}>
                    <Grid container spacing={1} p={1} m={5}>
                        <Grid item xs={12} md={12}>
                            <LineBarChart
                                width={500}
                                height={250}
                                data={confirmationAtLineBar}
                                key_x="confirmation_at"
                                y={
                                    {
                                        line: {
                                            key: "count",
                                            name: "日別感染者数"
                                        },
                                        bar: {
                                            key: "sub_total",
                                            name: "累計感染者数"
                                        },
                                    }
                                }
                            />
                        </Grid>
                    </Grid>

                </div>
                <div hidden={tabIndex !== 2}>
                    <Grid container spacing={1} p={1} m={5}>
                        <Grid item xs={12} md={6}>
                            <h2>感染確認日別感染者</h2>
                            <Paper style={{ height: 700, width: '100%', padding: '5px' }}>
                                <ReactVirtualizedTable
                                    data={confirmationAtGrouped}
                                    columns={[
                                        {
                                            width: 200,
                                            label: '感染確認日',
                                            dataKey: 'confirmation_at',
                                        },
                                        {
                                            width: 300,
                                            label: '件数',
                                            dataKey: 'count',
                                            numeric: true
                                        },
                                    ]}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <h2>都道府県別感染者</h2>
                            <Paper style={{ height: 700, width: '100%', overflowX: 'auto' }}>
                                <ReactVirtualizedTable
                                    data={prefectureGrouped}
                                    sort={({ defaultSortDirection, event, sortBy, sortDirection }) => {

                                    }}
                                    columns={[
                                        {
                                            width: 100,
                                            label: 'コード',
                                            dataKey: 'pref_code',
                                        },
                                        {
                                            width: 120,
                                            label: '都道府県',
                                            dataKey: 'prefecture_name',
                                        },
                                        {
                                            width: 300,
                                            label: '件数',
                                            dataKey: 'count',
                                            numeric: true,
                                            disableSort: false
                                        },
                                    ]}
                                    onRowClick={(data) => { navigate(`/covid-19/infected_persons/prefecture/${data.pref_code}`) }}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <h2>性別別感染者</h2>
                            <Paper style={{ height: 700, width: '100%', overflowX: 'auto' }}>
                                <ReactVirtualizedTable
                                    data={genderGrouped}
                                    columns={[
                                        {
                                            width: 200,
                                            label: '性別',
                                            dataKey: 'gender_name',
                                        },
                                        {
                                            width: 300,
                                            label: '件数',
                                            dataKey: 'count',
                                            numeric: true
                                        },
                                    ]}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <h2>年代別感染者</h2>
                            <Paper style={{ height: 700, width: '100%', overflowX: 'auto' }}>
                                <ReactVirtualizedTable
                                    data={ageGrouped}
                                    columns={[
                                        {
                                            width: 200,
                                            label: '年代',
                                            dataKey: 'age',
                                        },
                                        {
                                            width: 300,
                                            label: '件数',
                                            dataKey: 'count',
                                            numeric: true
                                        },
                                    ]}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </div>
                <div hidden={tabIndex !== 3}>
                    <Grid container spacing={1} p={1} m={5}>
                        <Grid item xs={12} md={12}>
                            <h2>感染者一覧</h2>
                            <Paper style={{ height: 700, width: '100%', overflowX: 'auto' }}>
                                <ReactVirtualizedTable
                                    data={rows}
                                    columns={[
                                        {
                                            width: 120,
                                            label: '都道府県',
                                            dataKey: 'prefecture_name',
                                        },
                                        {
                                            width: 120,
                                            label: 'No.',
                                            dataKey: 'no',
                                            numeric: true,
                                        },
                                        {
                                            width: 200,
                                            label: '感染確認日',
                                            dataKey: 'confirmation_at',
                                        },
                                        {
                                            width: 120,
                                            label: 'ステータス',
                                            dataKey: 'status_name',
                                        },
                                        {
                                            width: 120,
                                            label: '性別',
                                            dataKey: 'gender_name',
                                        },
                                        {
                                            width: 120,
                                            label: '年齢',
                                            dataKey: 'age',
                                        },
                                        {
                                            width: 120,
                                            label: '国籍',
                                            dataKey: 'nationality',
                                        },
                                        {
                                            width: 120,
                                            label: '職業',
                                            dataKey: 'profession',
                                        },
                                    ]}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <h2>感染者詳細一覧</h2>
                            <div style={{ height: 700, width: '100%', overflowX: 'auto' }}>
                                <ReactVirtualizedList
                                    data={rows}
                                    noRowsRenderer={() => {}}
                                    renderRow={(key, style, data) => {
                                        return (
                                            <div key={key} style={style}>
                                                <InfectedPersonCard data={data} />
                                            </div>
                                        )
                                    }}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </Layout>
    )
}

export default InfectedPersons

InfectedPersons.propTypes = {
    data: PropTypes.object.isRequired
}

export const query = graphql`
query InfectedPersons {
    allCovid19 {
      edges {
        node {
          id
          covid19_id
          status_id
          age {
            id
            name
            no
          }
          gender {
            id
            name
          }
          nationality
          no
          pref_code
          municipality
          prefecture {
            id
            name
            pref_code
          }
          status {
            id
            name
          }
          profession
          residence
          confirmation_at
          start_at
          dead_at
          end_at
          information_source
          created_at
          updated_at
        }
      }
    }
  }
`